import React from "react"
import PageBanner from "../components/common/PageBanner"
import Layout from "../components/navigation/Layout"

const posts = [
  {
    title: "Bevery Hills investor buys 1,200 unit affordable housing portfolio",
    href:
      "https://rew-online.com/beverly-hill-investor-acquires-1200-unit-affordable-housing-portfolio/",
    img: "https://rew-online.com/wp-content/uploads/2021/01/charlotte.jpg",
    outlet: "Real Estate Weekly",
    description:
      "Beverly Hills-based Stonebridge Global Partners has closed on $47 million in financing for the acquisition of seven affordable housing properties comprising over 1,200 apartments.",
  },
  {
    title:
      "Walker & Dunlop Structures $21.4 Million in Financing for Two Affordable Properties",
    href:
      "https://www.walkerdunlop.com/news-and-events/2020-12-22-walker-dunlop-structures-214-million-in-financing-for-two-affordable-properties/",
    img: "https://rew-online.com/wp-content/uploads/2021/01/charlotte.jpg",
    outlet: "Walker Dunlop",
    description:
      "Walker & Dunlop, Inc. announced today that it structured $12,500,000 in financing for Windsor Court Apartments and $8,925,000 for Lindsay Commons, two apartment complexes located in Milwaukee, Wisconsin. Both properties are affordable, with all 354 units having Section 8 HAP contracts and Low-Income Housing Tax Credit (LIHTC) restriction",
  },
  {
    title:
      "Gray Reed Represents Client in Purchase of Nine Multifamily Properties Totaling $100 Million in 2020",
    href:
      "https://www.grayreed.com/NewsResources/Press-Releases/191702/Gray-Reed-Represents-Client-in-Purchase-of-Nine-Multifamily-Properties-Totaling-100-Million-in-2020",
    img: "https://rew-online.com/wp-content/uploads/2021/01/charlotte.jpg",
    outlet: "Gray Reed",
    description:
      "Throughout 2020, Gray Reed represented Stonebridge Global Partners, LLC, of Beverly Hills, California, in the acquisition of nine affordable housing properties located in the Midwest and along the East Coast totaling $100 million.",
  },
  {
    title:
      "Beverly Hills investor pays $16 million for 239-unit apartment property in Milwaukee",
    href:
      "https://biztimes.com/beverly-hills-investor-pays-16-million-for-239-unit-apartment-property-in-milwaukee/",
    img:
      "https://assets.biztimes.com/2020/08/Windsor-Court-1-Milwaukee-2020-08-20-Google.jpg",
    outlet: "BizTimes",
    description:
      "An investment group based in Beverly Hills, California has acquired the 239-unit Windsor Court Apartments in the King Park neighborhood on Milwaukee’s near west side for $15.95 million, according to state records.",
  },
  {
    title:
      "Affordable Senior Housing Property in Historic Chicago Neighborhood Receives $14 Million in Financing via Walker & Dunlop",
    href:
      "https://www.prnewswire.com/news-releases/affordable-senior-housing-property-in-historic-chicago-neighborhood-receives-14-million-in-financing-via-walker--dunlop-300760781.html",
    img: "https://mma.prnewswire.com/media/794210/Willa_Rawls.jpg?w=500",
    outlet: "Cision",
    description:
      "Walker & Dunlop, Inc. announced today that it structured $13,920,000 in financing for Willa Rawls Manor, an 11-story, 123-unit affordable housing community for seniors aged 62 and above. The property was originally developed in 1979 and is 100 percent covered by a long-term Section 8 Housing Assistance Payments (HAP) contract.",
  },
  {
    title:
      "Financing for $42.4 Million, Five-Property Portfolio in New York and Michigan Arranged by Walker & Dunlop",
    href:
      "https://markets.businessinsider.com/news/stocks/financing-for-42-4-million-five-property-portfolio-in-new-york-and-michigan-arranged-by-walker-dunlop-1010435636",
    img: "https://mma.prnewswire.com/media/616274/Hay_Creek_Apartments.jpg",
    outlet: "Business Insider",
    description:
      "Two of the properties, Colonial Manor Apartments and Hay Creek Apartments, qualify as affordable housing due to Section 8 HAP contracts on 100 percent of the units. In addition, Hay Creek Apartments is entirely dedicated senior housing. The remaining three properties contain a combination of affordable and workforce housing units.",
  },
  {
    title: "Walker & Dunlop Arranges Financing for 3 Sec. 8 Properties",
    href:
      "https://www.housingfinance.com/finance/walker-dunlop-arranges-financing-for-3-sec-8-properties_o",
    img:
      "https://cdnassets.hw.net/dims4/GG/bb97948/2147483647/resize/300x%3E/quality/90/?url=https%3A%2F%2Fcdnassets.hw.net%2Fe1%2F1b%2F146fbafd4c06b89b3c39fa10b656%2Foak-hammock.jpg",
    outlet: "Affordable Housing Finance",
    description:
      "Walker & Dunlop (NYSE: WD) announced that it has arranged nearly $16 million in Freddie Mac financing for the acquisition of three Sec. 8 housing communities located in the Southeast.",
  },
  {
    title: "Stonebridge Refinances Trio of Affordable Assets",
    href:
      "https://www.multihousingnews.com/post/stonebridge-refinances-trio-of-affordable-assets/",
    img:
      "https://cdnassets.hw.net/dims4/GG/bb97948/2147483647/resize/300x%3E/quality/90/?url=https%3A%2F%2Fcdnassets.hw.net%2Fe1%2F1b%2F146fbafd4c06b89b3c39fa10b656%2Foak-hammock.jpg",
    outlet: "Multi-Housing News",
    description:
      "Bethesda, Md.— Walker & Dunlop Inc. has arranged $13.7 million for three multifamily buildings in Florida and Missouri. The borrower is Stonebridge Global Partners, a company which focuses primarily on acquiring and managing affordable housing projects.",
  },
]

const News = () => {
  return (
    <Layout>
      <PageBanner
        title=""
        backgroundImg="/img/backgrounds/headers/office1.jpg"
      />
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              Press releases
            </h2>
          </div>
          <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {posts.map(post => (
              <div key={post.title}>
                <div
                  onClick={() => {
                    window.open(post.href)
                  }}
                  className="mt-2 block"
                >
                  <p className="text-xl font-semibold text-gray-900">
                    {post.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {post.description}
                  </p>
                </div>
                <div className="mt-3">
                  <a
                    href={post.href}
                    className="text-base font-semibold text-branding"
                    target="_blank"
                  >
                    Read full story
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default News
